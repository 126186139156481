import React, { useState, useEffect } from "react";
import Header from "../inc/Header";
import Menu from "../inc/Menu";
import Loading from "../../components/common/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useNumberFormat from "../../hooks/useNumberFormat";
import { getMemberInfo } from "../../api/Member";
import { DateRange } from 'react-date-range';
import { addMonths, format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { getPointHist } from "../../api/Point";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const Point = () => {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const formatNumber = useNumberFormat();

  const [name, setName] = useState(""); // 이름
  const [id, setId] = useState(""); // 아이디
  const [point, setPoint] = useState(""); // 포인트
  const [optionText, setOptionText] = useState("1개월 · 최근순"); // 검색 초기값
  const [state, setState] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [points, setPoints] = useState([]);

  const { isLoading: isMemberLoading } = useQuery("memberInfo", getMemberInfo, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setName(data.name);
        setId(data.id);
        setPoint(formatNumber(data.max_points));
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  // 포인트 내역 불러오기
  const { mutate: fetchPointHist, isLoading: isPointHistLoading } = useMutation(getPointHist, {
    onSuccess: (data) => {
      if (data.success) {
        setPoints(data.points);
      } else {
        swal.fire(<p className="swal_text">포인트 내역을 불러오지 못했습니다.</p>);
      }
    },
    onError: () => {
      swal.fire(<p className="swal_text">포인트 내역을 불러오는 중 오류가 발생했습니다.</p>);
    },
  });

  const handleIncompleteMenu = (event) => {
    event.preventDefault(); // 링크 기본 동작을 막음
    swal.fire(<p className="swal_text">준비 중입니다.</p>);
  };

  const searchToggle = (event) => {
    event.preventDefault(); // 링크 기본 동작을 막음
    const searchBox = event.target.closest(".search-box");
    searchBox.classList.toggle("on");
  };

  const search = (event) => {
    event.preventDefault(); // 링크 기본 동작을 막음

    let range = document.querySelector('input[name="range"]:checked').nextElementSibling.innerHTML;
    const order = document.querySelector('input[name="order"]:checked').nextElementSibling.innerHTML;

    const { startDate, endDate } = state[0];
    const params = {
      startDate: startDate.toISOString().split('T')[0],  // 'YYYY-MM-DD' 형식으로 변환
      endDate: endDate.toISOString().split('T')[0],
      order: document.querySelector('input[name="order"]:checked').value
    };

    if (range === "직접입력") {
      range = `${params.startDate} ~ ${params.endDate}`;
    }

    setOptionText(`${range} · ${order}`);

    // API 호출 시 startDate, endDate, order를 함께 넘김
    fetchPointHist(params);
  };

  const rangeChange = (event) => {
    const range = event.target.nextElementSibling.innerHTML;
    const searchBox = event.target.closest(".search-box");

    if (range === "직접입력") {
      searchBox.classList.add("date");
    } else {
      const rangeMonth = event.target.value;
      setState([
        {
          startDate: addMonths(new Date(), rangeMonth * -1),
          endDate: new Date(),
          key: "selection",
        },
      ]);

      searchBox.classList.remove("date");
    }
  };

  // 화면 로드 시 기본값으로 조회
  useEffect(() => {
    document.querySelector('#searchBtn').click();
  }, []);  // 빈 배열을 전달하여 컴포넌트 마운트 시 한 번 실행

  const isLoading = isMemberLoading || isPointHistLoading;

  return (
    <div className="point sub">
      <Header title="포인트" link="/home" linkText="홈으로" />

      <div className="content">
        <div className="round-box-01">
          <h2>
            {name} <span>({id})</span>
          </h2>

          <div>
            <h3>보유포인트</h3>
            <p>
              {point} P
            </p>
          </div>

          <div>
            <button type="button" onClick={handleIncompleteMenu}>포인트 충전</button>
          </div>
        </div>

        <div className="point-list mt-38">
          <h3 className="mb-11">포인트 내역</h3>

          <div className="search-box">
            <button type="button" onClick={searchToggle}>{optionText}</button>

            <dl>
              <dt>조회기간</dt>
              <dd onChange={rangeChange}>
                <input type="radio" id="range1" name="range" value="1" defaultChecked />
                <label htmlFor="range1">1개월</label>
                <input type="radio" id="range2" name="range" value="3" />
                <label htmlFor="range2">3개월</label>
                <input type="radio" id="range3" name="range" value="6" />
                <label htmlFor="range3">6개월</label>
                <input type="radio" id="range4" name="range" value="0" />
                <label htmlFor="range4">직접입력</label>
              </dd>
              <dd>
                <DateRange
                  editableDateInputs={false}
                  onChange={item => setState([item.selection])}
                  ranges={state}
                  locale={ko}  // 한국어 로케일 적용
                  dateDisplayFormat="yyyy-MM-dd" // 날짜 표시 형식 문자열로 지정
                />
              </dd>
              <dt>정렬순서</dt>
              <dd>
                <input type="radio" id="order1" name="order" value="desc" defaultChecked />
                <label htmlFor="order1">최근순</label>
                <input type="radio" id="order2" name="order" value="asc"/>
                <label htmlFor="order2">과거순</label>
              </dd>
            </dl>

            <button type="button" id="searchBtn" onClick={search}>조회</button>
          </div>

          {points.length > 0 && (
          <ul>
            {points.map((point) => (
              <li key={point.point_key} className={point.points > 0 ? "plus" : "minus"}>
                <div>
                  <h4>{point.description || point.action_description}</h4>
                  <p>{format(new Date(point.created_at), 'yyyy.MM.dd')}</p>
                </div>
                <div>
                  <p>{point.points > 0 ? "+" : ""}{formatNumber(point.points)}P</p>
                  <p>{formatNumber(point.max_points)}P</p>
                </div>
              </li>
            ))}
          </ul>
          )}
          {points.length === 0 && <p>내역이 없습니다.</p>}
        </div>
      </div>

      <Menu />

      {isLoading && <Loading />}
    </div>
  );
};

export default Point;
