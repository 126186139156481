import Menu from "../inc/Menu";
import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate } from "react-router-dom";
import { getMemberInfo } from "../../api/Member";
import { fetchMerchantList } from "../../api/Merchant";
import { getCoinInfo } from "../../api/Coin";
import { sendPoint } from "../../api/Point";
import useNumberFormat from "../../hooks/useNumberFormat";
import Loading from "../../components/common/Loading";
import thumbnailImg from '../../assets/images/thumbnail.png';
import { QrReader } from 'react-qr-reader';

function Home() {
  let API_URL = process.env.REACT_APP_API_URL;
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const formatNumber = useNumberFormat();

  const [name, setName] = useState(""); // 이름
  const [point, setPoint] = useState(""); // 포인트

  const [coin, setCoin] = useState(""); // 코인
  const [usdt, setUSDT] = useState(""); // usdt
  const [won, setWon] = useState(""); // 원화
  const [merchantList, setMerchantList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false); // 이름

  const { isLoading: isMemberLoading } = useQuery("memberInfo", getMemberInfo, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setName(data.name);
        setPoint(data.max_points);
        setIsAdmin(data.is_admin);
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  const { isLoading: isCoinLoading } = useQuery("coinInfo", getCoinInfo, {
    enabled: point !== "",
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        const truncatedUSDT = Math.floor(parseFloat(data.usd_price) * 1000000) / 1000000;
        const truncatedWon = Math.floor(parseFloat(data.krw_price) * 100) / 100;

        setUSDT(truncatedUSDT); // usdt
        setWon(truncatedWon); // 원화
        setCoin(formatNumber(Math.floor(point / truncatedWon)));
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  const { isLoading: isMerchantLoading } = useQuery("merchant", fetchMerchantList, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setMerchantList(data.merchants);
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  // 가맹점 클릭 시 이동 처리 함수
  const handleMerchantClick = (merchant) => {
    navigate('/merchant/detail', {
      state: {
        link: "/home",
        linkText: "홈으로",
        content_file_1: merchant.content_file_1,
        content_file_2: merchant.content_file_2,
        content_file_3: merchant.content_file_3,
        content_file_4: merchant.content_file_4,
        content_file_5: merchant.content_file_5,
        is_owner: merchant.is_owner,
        merchant_key: merchant.merchant_key,
      }
    });
  };

  const [qrData, setQRData] = useState("2");
  const [pointToSend, setPointToSend] = useState("");
  const [showQRScanner, setShowQRScanner] = useState(false); // QR 스캐너 표시 상태
  const [showPointInput, setShowPointInput] = useState(false); // 포인트 입력 표시 상태

  // QR 코드 스캔 후 처리 함수
  const handleScan = (result, error) => {
    if (result) {
      setQRData(result.text); // QR 코드 데이터를 저장
      setShowQRScanner(false); // 스캔 완료 후 QR 스캐너 닫기
      setShowPointInput(true);
    } else if (error) {
      console.error(error); // 에러 발생 시 처리
    }
  };

  // 포인트 입력 후 전송 처리 함수
  const handleSendPoint = () => {
    setShowPointInput(false); // QR 코드 모달 닫기
    console.log(`Sending ${pointToSend} points to ${qrData}`);

    if (!pointToSend || !qrData) {
      Swal.fire('포인트와 QR 데이터를 입력해주세요', '', 'warning');
      return;
    }

    if (pointToSend > point) {
      Swal.fire('포인트가 부족합니다.', '', 'warning');
      setShowPointInput(true); // QR 코드 모달 닫기
      return;
    }

    sendPoints({ points: pointToSend, qrData: qrData }); // API 호출
  };

  // useMutation 훅 사용
  const { mutate: sendPoints, isSendLoading } = useMutation(sendPoint, {
    onSuccess: (data) => {
      console.log(data);
      Swal.fire('포인트 전송 성공!', '', 'success');
    },
    onError: (error) => {
      Swal.fire('포인트 전송 실패', error.message, 'error');
    },
  });

  // 모달 닫기 함수
  const handleCloseModal = () => {
    setShowQRScanner(false); // QR 코드 모달 닫기
  };

  // 포인트 입력 화면 닫기 함수
  const handlePointInputCloseModal = () => {
    setShowPointInput(false); // QR 코드 모달 닫기
  };

  const isLoading = isMemberLoading || isCoinLoading || isMerchantLoading || isSendLoading;

  return (
    <div className="home">
      <header>
        <h1>system group SMART</h1>
        {isAdmin && <Link to="/mng">관리자</Link>}
      </header>
      

      <div className="coin-box">
        <h2>
          반갑습니다. <span>{name}</span>님
        </h2>

        <div>
          <h3>MY COIN</h3>
          <p>
            <span>{coin}</span>
            <span>coin</span>
          </p>
        </div>

        <ul>
          <li>
            <h3>USDT</h3>
            <span>${usdt}</span>
          </li>
          <li>
            <h3>원화</h3>
            <span>₩{won}</span>
          </li>
        </ul>
      </div>

      <div className="point-box">
        <h2>MY Point</h2>
        <div>
          <span>누적포인트</span>
          <p>
            {formatNumber(point)}
            <span>Point</span>
          </p>
        </div>
      </div>

      <div className="btn-list">
        <ul>
          <li>
            <button type="button" className="point-send" onClick={() => setShowQRScanner(true)}>
              포인트 보내기
            </button>
          </li>
        </ul>
      </div>

      <div className="merchant-box">
        {merchantList.length > 0 && (
          <>
            <h3>가맹점 List</h3>
            <div className="merchant_list">
              {merchantList.map((merchant) => (
                <React.Fragment key={merchant.merchant_key}>
                  <div onClick={() => handleMerchantClick(merchant)} style={{ cursor: "pointer" }}>
                    <div>
                      {merchant.thumbnail ? (
                        <img src={`${API_URL}/file/${merchant.thumbnail}`} alt="썸네일" />
                      ) : (
                        <img src={thumbnailImg} alt="썸네일" />
                      )}
                    </div>
                    <div>
                      <h3>{merchant.name}</h3>
                      {merchant.description && <p>{merchant.description}</p>}
                      {merchant.url && (
                        <a href={merchant.url} target="_blank" rel="noreferrer">
                          {merchant.url}
                        </a>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </div>

      <Menu />

      {showQRScanner && (
        <div className="modal">
          <div className="scanner">
            <button type="button" className="close" onClick={handleCloseModal}>close</button>
            <QrReader
              delay={300}
              scanDelay={1000} // 스캔 간격을 1초로 설정하여 스캔이 안 되는 경우 방지
              onResult={handleScan}
              style={{ width: '100%', height: '100%' }}
              constraints={{ facingMode: "environment" }} // 후면 카메라 고정
            />
          </div>
        </div>
      )}

      {showPointInput && (
        <div className="modal">
          <div className="point-send">
            <label>포인트 입력</label>
            <input
              type="number"
              value={pointToSend}
              onChange={(e) => setPointToSend(e.target.value)}
            />
            <div>
              <button type="button" className="close-btn" onClick={handlePointInputCloseModal}>취소</button>
              <button type="button" className="send-btn" onClick={handleSendPoint}>확인</button>
            </div>
          </div>
        </div>
      )}
      
      {isLoading && <Loading />}
    </div>
  );
}

export default Home;
